@import "../../styles/mixins";

.wrapper {
    overflow: hidden;
    position: absolute;
}

.loading {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparentize($c-black, 0.8);

    @media (prefers-color-scheme: dark) {
        background-color: transparentize($c-white, 0.8);
    }

    svg {
        width: 15%;
        fill: $c-white;

        @media (prefers-color-scheme: dark) {
            fill: $c-black;
        }
    }
}

.name {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    display: flex;
    background-color: transparentize($c-white, 0.6);

    @media (prefers-color-scheme: dark) {
        background-color: transparentize($c-black, 0.6);
    }
}

.text {
    margin: auto;
    color: $c-black;
    font-size: 36px;

    @media (prefers-color-scheme: dark) {
        color: $c-white;
    }
}