@import "breakpoint";

$small-up: 480px;
$medium-up: 768px;
$page-width: 980px;
$max-width: 2980px;

$c-black: #0f0f0f;
$c-white: #ecede8;
$c-red: #d32147;

$c-primary: darken($c-red, 10%);
$c-primary-dark: lighten($c-red, 10%);

@mixin flexList {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

@mixin pageHeader {
    @include photoHeader;
}

@mixin photoHeader {
    text-align: left;
    margin-top: 40px;

    @include breakpoint($medium-up) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}
