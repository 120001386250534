@import "../../styles/mixins";

.wrapper {
    position: absolute;
    top: -100%;
    width: 100%;
}

.link {
    text-transform: uppercase;
    text-decoration: none;

    &:not(:first-child):before {
        content: '/';
        margin-left: 10px;
        padding-right: 10px;
        color: $c-black;

        @media (prefers-color-scheme: dark) {
            color: $c-white;
        }
    }
}