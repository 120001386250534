@import "../../styles/mixins";

.wrapper {
    text-align: center;
    max-width: $page-width;
    margin: 0 auto;
}

.header {
    @include pageHeader;
}

.portrait {
    max-width: 100%;
}

.copy {
    margin-bottom: 80px;

    @include breakpoint ($medium-up) {
        margin-bottom: 100px;
    }
}
