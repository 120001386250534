// Global styles
@import "styles/normalise";
@import "styles/mixins";

@import url('https://fonts.googleapis.com/css2?family=Karla&family=Permanent+Marker&display=swap');

html {
    background-color: $c-white;
    color: $c-black;
    font-size: 16px;
    font-family: 'Karla', sans-serif;
    overflow-y: overlay;

    @media (prefers-color-scheme: dark) {
        background-color: $c-black;
        color: $c-white;
    }
}

a {
    color: $c-primary;

    @media (prefers-color-scheme: dark) {
        color: $c-primary-dark;
    }
}


//App.jsx
.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page {
    flex-grow: 1;
}

.wrapper {
    margin: 0 10px;
    max-width: calc(100% - 20px);

    @include breakpoint($medium-up) {
        margin: 0 auto;
        padding: 0 50px;
        max-width: $max-width;
    }
}
