@import "../../styles/mixins";

.wrapper {
    margin: 0 auto;
    max-width: 1400px;
}

.header {
    @include pageHeader;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.item {
    width: 100%;
}

.container {
    position: relative;
    text-align: center;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 3em;
    text-decoration: none;
    font-weight: bold;
}
