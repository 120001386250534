@import "../../styles/mixins";

.list {
    @include flexList;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
}

.item {
    margin: 0 10px 10px;
}

.link {
    color: $c-primary;

    @media (prefers-color-scheme: dark) {
        color: $c-primary-dark;
    }
}

.icon {
    font-size: 36px;
    fill: $c-primary;

    @media (prefers-color-scheme: dark) {
        color: $c-primary-dark;
    }
}