@import "../../styles/mixins";

//Navigation.jsx
.wrapper {
    align-self: flex-start;
}

.menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid $c-primary;
    padding: 10px 20px;
    margin: 20px 10px 20px 0;
    color: $c-primary;

    @media (prefers-color-scheme: dark) {
        border-color: $c-primary-dark;
        color: $c-primary-dark;
    }
}

//NavigationLinks.jsx
.content {
    @include breakpoint(max-width 767px) {
        flex-grow: 1;
        display: flex;
    }
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;

    @include breakpoint(max-width 767px) {
        margin: auto;
        text-align: center;
        padding-top: 80px;
    }

    @include breakpoint(768px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 80px;

        >*:not(:first-child) {
            margin-left: 40px;
        }
    }
}

.link {
    text-decoration: none;
    position: relative;
    transition: color 250ms ease-in-out;
    color: $c-black;

    @media (prefers-color-scheme: dark) {
        color: $c-white;
    }

    @include breakpoint(max-width 767px) {
        display: block;
        font-size: 32px;
        margin-bottom: 25px;
    }

    @include breakpoint(768px) {
        &:after {
            display: block;
            content: '';
            border-bottom: solid 1px $c-primary;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;

            @media (prefers-color-scheme: dark) {
                border-bottom-color: $c-primary-dark;
            }
        }

        &:hover {
            color: rgba($c-black, 0.75);

            @media (prefers-color-scheme: dark) {
                color: rgba($c-white, 0.75);
            }

            &:after {
                transform: scaleX(1);
            }
        }
    }

    &.active {
        color: $c-primary;

        @media (prefers-color-scheme: dark) {
            color: $c-primary-dark;
        }

        @include breakpoint(768px) {

            &:hover {
                color: $c-primary;

                @media (prefers-color-scheme: dark) {
                    color: $c-primary-dark;
                }
            }

            &:after {
                transform: scaleX(1);
            }
        }
    }

    &.instagram {
        color: $c-primary;

        @media (prefers-color-scheme: dark) {
            color: $c-primary-dark;
        }

        &:after {
            display: none;
        }
    }
}
