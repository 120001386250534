@import "../../styles/mixins";

.wrapper {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($c-white, 0.95);
    overflow: auto;
    
    @media (prefers-color-scheme: dark) {
        background-color: rgba($c-black, 0.95);
    }
}

.content {
    min-height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}