@import '../../styles/mixins';

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    max-width: $max-width;

    @include breakpoint($medium-up) {
        margin: 0 auto;
        padding: 0 50px;
    }
}

.isHome {
    height: 42vh;

    @include breakpoint($medium-up) {
        height: 50vh;
    }
}

.header {
    display: flex;
    flex-direction: column;
    height: 80px;
}

.title {
    order: 1;
    margin: auto 0;
    text-transform: none;
}

.noLinkStyle {
    color: $c-black;

    @media (prefers-color-scheme: dark) {
        color: $c-white;
    }

    text-decoration: none;
}

.description {
    order: 2;
    margin: 0 0 10px;
    text-transform: uppercase;
}
